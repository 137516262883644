import { Button, Modal } from 'antd';
import React, { useRef, useState } from 'react';
import Draggable from 'react-draggable';
import {
    FacebookShareCount,
    HatenaShareCount,
    OKShareCount,
    PinterestShareCount,
    RedditShareCount,
    TumblrShareCount,
    VKShareCount
} from 'react-share';
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from 'react-share';

import {
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    FacebookMessengerShareButton,
    RedditShareButton,
    TumblrShareButton,
    PocketShareButton,
    PinterestShareButton,
    OKShareButton,
    MailruShareButton,
    LivejournalShareButton,
    LinkedinShareButton
} from 'react-share';

import { Input, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import Styled from './Styled';
import './Styles.css';

const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
        element.scrollLeft += step;
        scrollAmount += Math.abs(step);
        if (scrollAmount >= distance) {
            clearInterval(slideTimer);
        }
    }, speed);
};

const Share = ({ shareURL, visible, setVisible }) => {
    const contentWrapper = React.useRef(null);
    const [disabled, setDisabled] = useState(false);
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0
    });
    const draggleRef = useRef(null);

    const handleOk = (e) => {
        console.log(e);
        setVisible(false);
    };

    const handleCancel = (e) => {
        console.log(e);
        setVisible(false);
    };

    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y)
        });
    };

    const shareTitle = 'Blog Post';
    return (
        <>
            <Modal
                title={
                    <div
                        style={{
                            width: '100%',
                            cursor: 'move'
                        }}
                        onMouseOver={() => {
                            if (disabled) {
                                setDisabled(false);
                            }
                        }}
                        onMouseOut={() => {
                            setDisabled(true);
                        }} // fix eslintjsx-a11y/mouse-events-have-key-events
                        // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                        onFocus={() => {}}
                        onBlur={() => {}} // end
                    >
                        Share Post
                    </div>
                }
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                // modalRender={(modal) => (
                //     <Draggable
                //         disabled={disabled}
                //         bounds={bounds}
                //         onStart={(event, uiData) => onStart(event, uiData)}>
                //         <div ref={draggleRef}>{modal}</div>
                //     </Draggable>
                // )}
                >
                <Styled.Container>
                    {/* <Styled.Title>Horizontal Scroll Buttons</Styled.Title> */}
                    <div className="overflow-x-auto" ref={contentWrapper}>
                        <FacebookShareButton
                            url={shareURL}
                            // hashtag={'#Hashtag #Bosseth'}
                            description={'aiueo'}
                            className="Demo__some-network__share-button">
                            <FacebookIcon size={42} round />{' '}
                            <span className="icon-text">Facebook</span>
                        </FacebookShareButton>
                        <TwitterShareButton
                            title={shareTitle}
                            url={shareURL}
                            hashtags={['hashtag1', 'hashtag2']}>
                            <TwitterIcon size={42} round />{' '}
                            <span className="icon-text">Twitter</span>
                        </TwitterShareButton>
                        <EmailShareButton
                            title={shareTitle}
                            url={shareURL}
                            hashtags={['hashtag1', 'hashtag2']}>
                            <EmailIcon size={42} round /> <span className="icon-text">E-Mail</span>
                        </EmailShareButton>
                        <WhatsappShareButton
                            title={shareTitle}
                            url={shareURL}
                            hashtags={['hashtag1', 'hashtag2']}>
                            <WhatsappIcon size={42} round />{' '}
                            <span className="icon-text">WhatsApp</span>
                        </WhatsappShareButton>
                        <TelegramShareButton
                            title={shareTitle}
                            url={shareURL}
                            hashtags={['hashtag1', 'hashtag2']}>
                            <TelegramIcon size={42} round />{' '}
                            <span className="icon-text">Telegram</span>
                        </TelegramShareButton>
                        <FacebookMessengerShareButton
                            title={shareTitle}
                            url={shareURL}
                            hashtags={['hashtag1', 'hashtag2']}>
                            <FacebookMessengerIcon size={42} round />{' '}
                            <span className="icon-text">Messanger</span>
                        </FacebookMessengerShareButton>
                        <RedditShareButton
                            title={shareTitle}
                            url={shareURL}
                            hashtags={['hashtag1', 'hashtag2']}>
                            <RedditIcon size={42} round /> <span className="icon-text">Reddit</span>
                        </RedditShareButton>
                        <TumblrShareButton
                            title={shareTitle}
                            url={shareURL}
                            hashtags={['hashtag1', 'hashtag2']}>
                            <TumblrIcon size={42} round /> <span className="icon-text">Tumblr</span>
                        </TumblrShareButton>
                        <PocketShareButton
                            title={shareTitle}
                            url={shareURL}
                            hashtags={['hashtag1', 'hashtag2']}>
                            <PocketIcon size={42} round /> <span className="icon-text">Pocket</span>
                        </PocketShareButton>
                        <PinterestShareButton
                            title={shareTitle}
                            url={shareURL}
                            hashtags={['hashtag1', 'hashtag2']}>
                            <PinterestIcon size={42} round />{' '}
                            <span className="icon-text">Pinterest</span>
                        </PinterestShareButton>
                        <OKShareButton
                            title={shareTitle}
                            url={shareURL}
                            hashtags={['hashtag1', 'hashtag2']}>
                            <OKIcon size={42} round /> <span className="icon-text">OK</span>
                        </OKShareButton>

                        <MailruShareButton
                            title={shareTitle}
                            url={shareURL}
                            hashtags={['hashtag1', 'hashtag2']}>
                            <MailruIcon size={42} round /> <span className="icon-text">Mailru</span>
                        </MailruShareButton>
                        <LivejournalShareButton
                            title={shareTitle}
                            url={shareURL}
                            hashtags={['hashtag1', 'hashtag2']}>
                            <LivejournalIcon size={42} round />{' '}
                            <span className="icon-text">Livejournal</span>
                        </LivejournalShareButton>
                        <LinkedinShareButton
                            title={shareTitle}
                            url={shareURL}
                            hashtags={['hashtag1', 'hashtag2']}>
                            <LinkedinIcon size={42} round />{' '}
                            <span className="icon-text">Linkedin</span>
                        </LinkedinShareButton>
                    </div>
                    <Styled.ButtonWrapper>
                        {/* <Styled.Button
                            onClick={() => {
                                sideScroll(contentWrapper.current, 25, 100, -10);
                            }}>
                            Left
                        </Styled.Button>
                        <Styled.Button
                            onClick={() => {
                                sideScroll(contentWrapper.current, 25, 100, 10);
                            }}>
                            Right
                        </Styled.Button> */}
                        
                    </Styled.ButtonWrapper>
                    <Input.Group>
                        <Input
                            style={{ width: 'calc(100% - 40px)',  margin: 'auto' }}
                            defaultValue={shareURL}
                            disabled
                        />
                        <Tooltip title="copy post url">
                            <Button icon={<CopyOutlined />} />
                        </Tooltip>
                    </Input.Group>
                </Styled.Container>
            </Modal>
        </>
    );
};

export default Share;
