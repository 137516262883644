import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Spin } from 'antd';
import { Tag, Divider } from 'antd';

function TagsComponent({ TagsData }) {
    return (
        <>
{
    TagsData ? (
        TagsData.map((tag, index) => (
            <span className="blog-tag" key={index}>
                <Link to={`/blog/tags/${tag.id}`}>
                    <Tag color="rgba(7, 165, 142, 0.25)" className="blog-tag">
                        {tag?.attributes.name}
                    </Tag>
                </Link>
            </span>
        ))
    ) : (
        <div className="center">
            <Space size="middle">
                {/* <Spin size="small" />
                                <Spin /> */}
                <Spin size="small" />
            </Space>
        </div>
    )
}

        </>
    )
}

export default TagsComponent;
