import { Tags, BlogMenu, BlogCategories } from '../../dummyData';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetCategories, GetPosts, GetTags } from '../../redux/thunk/blog.thunks';
import { Link, useParams } from 'react-router-dom';

function BlogNav() {
    const { id } = useParams();

    const dispatch = useDispatch();
    const { isLoading, posts, tags, categories, errorMessage } = useSelector((state) => state.blog);

    useEffect(() => {
        dispatch(GetPosts());
        dispatch(GetTags());
        dispatch(GetCategories());
    }, []);

    return (
        <div>
            <nav className="container">
                <ul className="nav-bar px-auto nav-overflow">
                    {categories.data &&
                        categories.data.map((category, index) => (
                            <span className="blog-tag" key={index}>
                                <Link to={`/blog/categories/${category.id}`}>
                                    <li className="nav-item active">
                                        <span
                                            className={`nav-link ${
                                                category.id == id
                                                    ? 'active-blog-menu text-red'
                                                    : ''
                                            }`}>
                                            {category.attributes.name}{' '}
                                            <span className="sr-only">(current)</span>
                                        </span>
                                    </li>
                                </Link>
                            </span>
                        ))}
                </ul>
            </nav>
{/* 
            <nav
                class="navbar navbar-expand-lg navbar-light md-none"
                style={{ justifyContent: 'center' }}>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarTogglerDemo03"
                    aria-controls="navbarTogglerDemo03"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarTogglerDemo03">



                                    <ul className="nav-bar px-auto nav-overflow">
                    {categories.data &&
                        categories.data.map((category, index) => (
                            <span className="blog-tag" key={index}>
                                <Link to={`/blog/categories/${category.id}`}>
                                    <li className="nav-item active">
                                        <span
                                            className={`nav-link ${
                                                category.id == id
                                                    ? 'active-blog-menu text-red'
                                                    : ''
                                            }`}>
                                            {category.attributes.name}{' '}
                                            <span className="sr-only">(current)</span>
                                        </span>
                                    </li>
                                </Link>
                            </span>
                        ))}
                </ul>
                </div>
            </nav> */}
        </div>
    );
}

export default BlogNav;
