import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import '../css/alice-carousel.css';
export const InvalidAvatar = ({ placeholder, customClass, dataAos }) => {
    return (
        <>
            <div
                data-aos={dataAos}
                className={`why-huzz-icons-courosel placeholder-avatar ${customClass}`}
            >
                <h1>{placeholder}</h1>
            </div>
        </>
    );
};
const items = [
    <div className="item col-md-12 text-center justify" data-value="2">
        <div className="">
            <div className=" p-2">
                <div className="coloured mb-0 coloured-courosel">
                    <h5 className="text-black">
                        <b> Laraberyl Fabrics </b>
                    </h5>
                    <img className="" alt="" src="./assets/img/five-star.svg" />
                    <img
                        className="why-huzz-icons-courosel customer-icon"
                        alt=""
                        src="./assets/img/customer-icons/customer-1.jpg"
                        data-aos="fade-up"
                    />
                </div>
                <div className="monochrome monochrome-courosel">
                    <p>
                        Every business owner needs this app, especially a business that is just
                        starting. It helps to track your finances without any stress.
                    </p>
                </div>
            </div>
        </div>
    </div>,
    <div className="item col-md-12 text-center justify" data-value="2">
        <div className="">
            <div className="  p-8">
                <div className="coloured mb-0 coloured-courosel">
                    <h5 className="text-black">
                        <b> Temiasese Foods </b>
                    </h5>
                    <img className="" alt="" src="./assets/img/five-star.svg" />
                    <img
                        className="why-huzz-icons-courosel customer-icon"
                        alt=""
                        src="./assets/img/customer-icons/customer-2.jpg"
                        data-aos="fade-up"
                    />
                </div>
                <div className="monochrome monochrome-courosel">
                    {/* <h5><b> Joshua Olatunde </b></h5> */}
                    <p>I use huzz app. It is amazing I must say.👏👏</p>
                </div>
            </div>
        </div>
    </div>,
    <div className="item col-md-12 text-center justify" data-value="2">
        <div className="">
            <div className=" p-2">
                <div className="coloured mb-0 coloured-courosel">
                    <h5 className="text-black">
                        <b> Sky Team </b>
                    </h5>
                    <img className="" alt="" src="./assets/img/five-star.svg" />
                    {/* <p  >Log, manage and keep track of your business or personal transactions</p> */}
                    <InvalidAvatar
                        placeholder={'S'}
                        customClass={'customer-icon'}
                        dataAos={'fade-up'}
                    />
                </div>
                <div className="monochrome monochrome-courosel">
                    {/* <h5><b> Joshua Olatunde </b></h5> */}
                    <p>
                        Fantastic app, it is a privilege to be the first person to review the app.
                        It has a really nice intuitive interface, I wonder why they haven't
                        developed it since 2019 i'm searching for kind of product.
                    </p>
                </div>
            </div>
        </div>
    </div>,
    <div className="item col-md-12 text-center justify" data-value="2">
        <div className="">
            <div className=" p-2">
                <div className="coloured mb-0 coloured-courosel">
                    <h5 className="text-black">
                        <b> Josephine Shobande </b>
                    </h5>
                    <img className="" alt="" src="./assets/img/five-star.svg" />
                    {/* <p  >Log, manage and keep track of your business or personal transactions</p> */}
                    <InvalidAvatar
                        placeholder={'J'}
                        customClass={'customer-icon'}
                        dataAos={'fade-up'}
                    />
                </div>
                <div className="monochrome monochrome-courosel">
                    {/* <h5><b> Joshua Olatunde </b></h5> */}
                    <p>
                        This looks very clean and sleek. Now i can keep effective track of all my
                        expenses.
                    </p>
                </div>
            </div>
        </div>
    </div>,
    <div className="item col-md-12 text-center justify" data-value="2">
        <div className="">
            <div className="  p-8">
                <div className="coloured  mb-0 coloured-courosel">
                    <h5 className="text-black">
                        <b> Olanrewaju Muritala </b>
                    </h5>
                    <img className="" alt="" src="./assets/img/five-star.svg" />
                    <InvalidAvatar
                        placeholder={'O'}
                        customClass={'customer-icon'}
                        dataAos={'fade-up'}
                    />
                </div>
                <div className="monochrome monochrome-courosel">
                    {/* <h5><b> Joshua Olatunde </b></h5> */}
                    <p>Very cool app I recommend it for all.</p>
                </div>
            </div>
        </div>
    </div>
];
const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 }
};
export const Carousel = () => (
    <section className="why-huzz why-huzz-courosel">
        <div className="container mt-0">
            <h1>Trusted by 200+ businesses</h1>
            <AliceCarousel
                autoPlay
                // autoPlayControls
                autoPlayStrategy="none"
                autoPlayInterval={5000}
                animationDuration={1000}
                animationType="fadeout"
                infinite
                touchTracking={false}
                // disableDotsControls
                disableButtonsControls
                items={items}
                responsive={responsive}
            />
        </div>
    </section>
);
