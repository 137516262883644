import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Spin } from 'antd';
import Moment from 'moment';

function RecentPosts({ PostsData }) {
var last3 = []
    if(PostsData){

last3 = PostsData.slice(-3).reverse(); // 👉️ ['c', 'd', 'e']

    }



    return (
        <>
            {PostsData ? (
                last3.map((post, index) => (
                    <div key={index} className="row mb-10">
                        <div className="col-4 mt-2 ">
                            <div className=" pl-10 image-overflow max-h-100">
                                <img
                                    src={`${
                                        post.attributes.image_url
                                            ? `https://cms.huzz.africa${post.attributes.image_url}`
                                            : '/assets/img/blog/blog-post-image.svg'
                                    }`}
                                    className="max-width-fill"
                                />
                            </div>
                        </div>
                        <div className="col-8   pr-10">
                            <div className="">
                                {/* <p className="line-height text-12 elipsis-3">
                                    {post.attributes.description}
                                </p> */}
                                <p
                                    className="line-height text-12 elipsis-3"
                                    dangerouslySetInnerHTML={{
                                        __html: post.attributes.description
                                    }}></p>

                                <Link to={`/blog/details/${post.id}#`}>
                                    {' '}
                                    <span className="text-red underline text-12 right mr-10">
                                        Read more
                                    </span>{' '}
                                </Link>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="center">
                    <Space size="middle">
                        {/* <Spin size="small" />
                                <Spin /> */}
                        <Spin size="small" />
                    </Space>
                </div>
            )}
        </>
    );
}

export default RecentPosts;
