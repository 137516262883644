import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Spin } from 'antd';

function CategoriesComponent({ categoriesData }) {
    return (
        <>
            {categoriesData ? (
                categoriesData.map((category, index) => (
                    <div key={index} className="col-6 mt-0 ">
                        <div className=" px-10">
                            <Link to={`/blog/categories/${category.id}#`}>
                                {' '}
                                <p className="tag-label text-black inline mr-10 text-black-200">
                                    {category.attributes.name}
                                </p>
                            </Link>
                            <span className="text-gray-100">
                                {'('}
                                {category.attributes.posts.data.length}
                                {')'}
                            </span>
                        </div>
                    </div>
                ))
            ) : (
                <div className="center">
                    <Space size="middle">
                        {/* <Spin size="small" />
                                <Spin /> */}
                        <Spin size="small" />
                    </Space>
                </div>
            )}
        </>
    );
}

export default CategoriesComponent;
