import './Styles.css';

const LikeButton = ({children}) => {
    var animateButton = function (e) {
        // e.preventDefault;
        //reset animation
        e.target.classList.remove('animate');

        e.target.classList.add('animate');
        setTimeout(function () {
            e.target.classList.remove('animate');
        }, 700);
    };

    return (
        <>
            <span class="confetti-button" onClick={animateButton}>
                {children}
            </span>
        </>
    );
};

export default LikeButton;
