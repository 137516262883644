// import { useEffect, useState } from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import ScrollTop from '../../components/scrollTop';
import BlogNav from '../../components/Blog/BlogNav';
import BlogShowcase from '../../components/Blog/BlogShowcase';
// import { Carousel, InvalidAvatar } from '../components/carousel';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import { useHistory, useLocation } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import BlogTopNav from '../../components/Blog/BlogTopNav';

import '../../css/blog.css';
import { Pagination } from 'antd';
import { Tag, Divider } from 'antd';
import { Tags, BlogMenu, BlogCategories } from '../../dummyData';
import { GetCategories, GetPosts, GetTags, GetSinglePost } from '../../redux/thunk/blog.thunks';
import { useDispatch, useSelector } from 'react-redux';
import HeroPost from '../../components/Blog/HeroPost';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import Share from '../../components/Blog/Share/Share';
import LikeButton from '../../components/Blog/LikeButton/LikeButton';

function Details() {
    const [visible, setVisible] = useState(false);
    const showModal = () => {
        setVisible(true);
    };

    const dispatch = useDispatch();
    const { id } = useParams();
    const { isLoading, posts, tags, categories, errorMessage, post } = useSelector(
        (state) => state.blog
    );
    useEffect(() => {
        dispatch(GetPosts());
        dispatch(GetSinglePost(id));
        dispatch(GetTags());
        dispatch(GetCategories());

        const attributes = post.data;
        console.log(attributes);
    }, []);

    return (
        <div className="blog">
            <BlogTopNav id="top" />
            <section className="blog-section section-one">
                <BlogShowcase />
            </section>
            <section className="main bg-teal">
                <section className="blog-section section-two">
                    <BlogNav />
                </section>
                <section className="blog-showcase container">
                    <div className="row">
                        <div className="col-md-12 mt-2 p-10">
                            {/* <HeroPost PostsData={posts.data} /> */}
                            <div className="bg-white p-20">
                                <img
                                    src={`${
                                        post.data
                                            ? `https://cms.huzz.africa${post.data.attributes.image_url}`
                                            : '/assets/blog/blog-details-full-image.svg'
                                    }`}
                                    className="max-width-fill"
                                />

                                <div className="py-10">
                                    {' '}
                                    <span className="mr-10 tag-label">
                                        {post.data?.attributes?.category?.data?.attributes?.name}
                                    </span>
                                    <span>
                                        {Moment(post?.data?.attributes?.publishedAt).format(
                                            'MMM d'
                                        )}
                                    </span>
                                </div>
                                <h1 className="tag-label text-green text-36">
                                    {post.data && post.data.attributes.title}
                                </h1>
                                <p  dangerouslySetInnerHTML={{ __html: post.data && post.data.attributes.description }}>
                                    
                                </p>
                                <div className="py-10 flex justify-between">
                                    {' '}
                                    <span className="flex">
                                        {post.data &&
                                            post.data.attributes.tags.data.map((tag, index) => (
                                                <a
                                                    href="#"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="mr-10">
                                                    {' '}
                                                    <span>
                                                        {' '}
                                                        <Link
                                                            to={`/blog/tag/${tag.id}`}
                                                            rel="noreferrer"
                                                            className="mr-10 text-red underline font-weight-500">
                                                            {tag.attributes.name}
                                                        </Link>
                                                    </span>{' '}
                                                </a>
                                            ))}
                                    </span>
                                </div>
                                <div className="py-10 flex justify-between">
                                    {' '}
                                    <span className="text-red underline">
                                        {' '}
                                        <a
                                            href="#"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="mr-10 text-red underline font-weight-500">
                                            {' '}
                                        </a>
                                    </span>
                                    <span className="flex">
                                        {/* <a
                                            href="#"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="mr-10">
                                            {' '}
                                            <span>
                                                <img src="/assets/img/blog/like.svg" />
                                            </span>{' '}
                                        </a> */}
                                        {/* <a
                                            href="#"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="mr-10">
                                            {' '}
                                            <span>
                                                <img src="/assets/img/blog/comment.svg" />
                                            </span>{' '}
                                        </a> */}
                                        {/* <span rel="noreferrer" className="mr-10">
                                            {' '}
                                            <span>
                                                <img src="/assets/img/blog/share.svg" />
                                            </span>{' '}
                                        </span> */}
                                        <span onClick={showModal} className="mr-10 cursor-pointes">
                                            {' '}
                                            <span>
                                                <LikeButton>
                                                    <img src="/assets/img/blog/share.svg" />
                                                </LikeButton>
                                            </span>{' '}
                                        </span>
                                        
                                        {/* <a
                                            href="#"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="mr-10">
                                            {' '}
                                            <span>
                                                <img src="/assets/img/blog/bookmark.svg" />
                                            </span>{' '}
                                        </a> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </section>
            <ScrollTop />
            <Footer />
            <Share
                visible={visible}
                setVisible={setVisible}
                shareURL={`http://huzz.africa/blog/details/${post.data?.id}`}
            />
            ;
        </div>
    );
}
export default Details;
