import actions from '../actions/blog.actions';
import api from '../../api/apiClient';
import { Button, notification } from 'antd';
import React from 'react';

export const GetPosts = () => async (dispatch) => {
    dispatch(actions.usersLoadStart());
    await api()
        .get('posts?populate=*')
        .then((response) => dispatch(actions.usersLoadSuccess(response.data)))
        .catch((error) => {
            dispatch(
                notification.open({
                    message: 'Oops Error!',
                    description: error.message,
                    onClick: () => {
                        console.log('Notification Clicked!');
                    }
					
                })
            );
            dispatch(actions.usersLoadError(error.message));
        });
};
export const GetTags = () => async (dispatch) => {
    dispatch(actions.tagsLoadStart());
    await api()
        .get('tags?populate=*')
        .then((response) => dispatch(actions.tagsLoadSuccess(response.data)))
        .catch((error) => dispatch(actions.tagsLoadError(error.message)));
};
export const GetCategories = () => async (dispatch) => {
    dispatch(actions.tagsLoadStart());
    await api()
        .get('categories?populate=*')
        .then((response) => dispatch(actions.categoriesLoadSuccess(response.data)))
        .catch((error) => dispatch(actions.categoriesLoadError(error.message)));
};

export const GetSinglePost = (id) => async (dispatch) => {
    dispatch(actions.tagsLoadStart());
    await api()
        .get(`posts/${id}?populate=*`)
        .then((myresponse) => dispatch(actions.singlePostLoadSuccess(myresponse.data)))
        .catch((error) => dispatch(actions.singlePostLoadError(error.message)));
};
