// import { useEffect, useState } from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import ScrollTop from '../../components/scrollTop';
import BlogNav from '../../components/Blog/BlogNav';
import BlogTopNav from '../../components/Blog/BlogTopNav';
import BlogShowcase from '../../components/Blog/BlogShowcase';
import SearchBlog from '../../components/Blog/SearchBlog';
import CategoriesComponent from '../../components/Blog/Categories.Component';
import TagsComponent from '../../components/Blog/Tags.Component';
import HeroPost from '../../components/Blog/HeroPost';
import BasePosts from '../../components/Blog/BasePosts';
import RecentPosts from '../../components/Blog/RecentPosts';
import '../../css/blog.css';
import '../../css/blogNavbar.css';
import { Pagination } from 'antd';
import { Tag, Divider } from 'antd';
import { Tags, BlogMenu, BlogCategories } from '../../dummyData';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetCategories, GetPosts, GetTags } from '../../redux/thunk/blog.thunks';
import { Space, Spin } from 'antd';
import Moment from 'moment';
import { Link, useParams } from 'react-router-dom';


function Blog() {
    let { id } = useParams();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetPosts());
        dispatch(GetTags());
        dispatch(GetCategories());
    }, []);
    const { isLoading, posts, tags, categories, errorMessage } = useSelector((state) => state.blog);
    let singleTag = '';

    if (tags.data) {
        id = parseInt(id) - 1;
        singleTag = tags.data[id];
        console.log(singleTag.attributes.posts.data);
        console.log(id, typeof id);
    }

    return (
        <div className="blog">
            <BlogTopNav id="top" />
            <section className="blog-section section-one">
                <BlogShowcase />
            </section>
            <section className="main bg-teal">
                <section className="blog-section section-two">
                    <BlogNav categories={categories} />
                </section>
                <section className="container md-none">
                    <SearchBlog />
                    <div className="mb-20 bg-white p-10">
                        <span className="mr-10 tag-label pl-10">Categories</span>
                        <div className="row">
                            <CategoriesComponent categoriesData={categories.data} />
                        </div>
                    </div>
                </section>
                <section className="blog-showcase container">
                    <div className="row">
                        <div className="col-md-7 mt-2  p-10">
                            <div className="bg-white p-20">
                                {singleTag.attributes?.posts.data &&
                                    console.log(singleTag.attributes?.posts.data)}
                                {singleTag.attributes?.posts.data && (
                                    <HeroPost PostsData={singleTag.attributes?.posts.data} />
                                )}
                            </div>
                        </div>
                        <div className="col-md-5 mt-2  p-10 sm-none">
                            <div className=" ">
                                <SearchBlog />

                                <div className="mb-20 bg-white p-10">
                                    <span className="mr-10 tag-label pl-10">Categories</span>
                                    <div className="row">
                                        <CategoriesComponent categoriesData={categories.data} />
                                    </div>
                                </div>
                                <div className="mb-20 bg-white p-10">
                                    <span className="mr-10 tag-label pl-10">Recent Posts</span>
                                    <RecentPosts PostsData={posts.data} />
                                </div>
                                <div className="mb-20 bg-white p-10">
                                    <span className="mr-10 tag-label pl-10">Tags</span>
                                    <TagsComponent TagsData={tags.data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blog-showcase container">
                    <div className=" p-10 ">
                        <div className="row">
                            <BasePosts PostsData={posts.data} />
                        </div>
                    </div>
                </section>
            </section>
            <section className=" bg-teal">
                <div className=" pagenation container m-auto flex justify-center p-10 ">
                    {true && (
                        <Pagination
                            onChange={({ page }) => {
                                console.log(page);
                                // console.log(pageSize);
                            }}
                            // onChange={({ current, size }) => {
                            //     alert(current)
                            //     alert(size)
                            // }}
                            size="small"
                            // total={100}
                            total={posts.meta?.pagination?.pageCount}
                            onShowSizeChange={({ current, size }) => {
                                console.log(current);
                                console.log(size);
                            }}
                        />
                    )}
                </div>
                <br />
            </section>
            <ScrollTop />
            <Footer />
        </div>
    )
}
export default Blog;
