import React from 'react';
const Accordion = (props) => {
    return (
        <>
            <div className="accordion-item">
                <h2 className="accordion-header" id={`heading${props.accordionNumber}`}>
                    <button
                        type="button"
                        className="accordion-button collapsed"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${props.accordionNumber}`}
                    >
                        {props.accordionTitle}
                    </button>
                </h2>
                <div
                    id={`collapse${props.accordionNumber}`}
                    className={`accordion-collapse collapse ${
                        props.accordionNumber === '1' ? 'show' : 'dont-show'
                    }`}
                    data-bs-parent="#myAccordion"
                >
                    <div className="card-body">
                        <p dangerouslySetInnerHTML={{ __html: props.accordionContent }}></p>
                    </div>
                </div>
            </div>
            {/* <div class="accordion-item">
            <h2 class="accordion-header" id={`heading${props.accordionNumber}`}>
                <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={`#collapse${props.accordionNumber}`}>{props.accordionTitle}</button>									
            </h2>
            <div id={`collapse${props.accordionNumber}`} class={`accordion-collapse collapse ${props.accordionNumber === "One" ? "show" : "dont-show"}`} data-bs-parent="#myAccordion">
                <div class="card-body">
                <p dangerouslySetInnerHTML={{__html: props.accordionContent}}></p>
                </div>
            </div>
        </div>  */}
        </>
    );
};
export default Accordion;
