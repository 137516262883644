import { Link } from 'react-router-dom';
import { Space, Spin, Skeleton } from 'antd';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import Share from './Share/Share';
import LikeButton from './LikeButton/LikeButton';

function HeroPost({ PostsData }) {
    const [visible, setVisible] = useState(false);
    const showModal = () => {
        setVisible(true);
    };

    return (
        <>
            {/* {console.log(PostsData)} */}

            {/* 
            {if(PostsData = true){
                console.log(".")
            }else{

            }} */}

            {PostsData ? (
                PostsData.slice(-1).map((post, index) => (
                    <>
                        {' '}
                        <img
                            src={`${
                                post.attributes.image_url
                                    ? `https://cms.huzz.africa${post.attributes.image_url}`
                                    : '/assets/gim /blog/main-bg.svg'
                            }`}
                            className="max-width-fill"
                        />
                        <div className="py-10">
                            {' '}
                            <span className="mr-10 tag-label">
                                {post.attributes.category?.data.attributes.name &&
                                    post.attributes.category?.data.attributes.name}
                            </span>
                            <span>{Moment(post.attributes.publishedAt).format('MMM d')}</span>
                        </div>
                        <h1 className="tag-label text-green text-36">{post.attributes.title}</h1>
                        {/* <p className="sm-elipsis-6 elipsis-3">{post.attributes.description}</p> */}
                        <p
                            className="sm-elipsis-6 elipsis-3"
                            dangerouslySetInnerHTML={{ __html: post.attributes.description }}></p>
                        <div className="py-10 flex justify-between">
                            {' '}
                            {/* <span className="text-red underline">
                                {' '}
                                <Link
                                    to={`/blog/details/${post.id}#`}
                                    rel="noreferrer"
                                    className="mr-10 text-red underline font-weight-500">
                                    Tag
                                </Link>
                            </span>
                            <span className="text-red underline">
                                {' '}
                                <Link
                                    to={`/blog/details/${post.id}#`}
                                    rel="noreferrer"
                                    className="mr-10 text-red underline font-weight-500">
                                    Tag
                                </Link>
                            </span>
                            <span className="text-red underline">
                                {' '}
                                <Link
                                    to={`/blog/details/${post.id}#`}
                                    rel="noreferrer"
                                    className="mr-10 text-red underline font-weight-500">
                                    Tag
                                </Link>
                            </span>
                            <span className="text-red underline">
                                {' '}
                                <Link
                                    to={`/blog/details/${post.id}#`}
                                    rel="noreferrer"
                                    className="mr-10 text-red underline font-weight-500">
                                    Tag
                                </Link>
                            </span> */}
                            <span className="flex">
                                {post.attributes.tags?.data &&
                                    post.attributes.tags?.data.map((tag, index) => (
                                        <a
                                            href="#"
                                            target="_blank"
                                            rel="noreferrer"
                                            className="mr-10">
                                            {' '}
                                            <span>
                                                {' '}
                                                <Link
                                                    to={`/blog/tags/${tag.id}`}
                                                    rel="noreferrer"
                                                    className="mr-10 text-red underline font-weight-500">
                                                    {tag.attributes.name}
                                                </Link>
                                            </span>{' '}
                                        </a>
                                    ))}
                            </span>
                        </div>
                        <div className="py-10 flex justify-between">
                            {' '}
                            <span className="text-red underline">
                                {' '}
                                <Link
                                    to={`/blog/details/${post.id}`}
                                    rel="noreferrer"
                                    className="mr-10 text-red underline font-weight-500">
                                    Read more
                                </Link>
                            </span>
                            <span className="flex">
                                {/* <span rel="noreferrer" className="mr-10">
                                    {' '}
                                    <span>
                                        <LikeButton>
                                            <img src="./assets/img/blog/like.svg" />
                                        </LikeButton>
                                    </span>{' '}
                                </span> */}
                                {/* <span rel="noreferrer" className="mr-10">
                                    {' '}
                                    <span>
                                        <LikeButton>
                                            <img src="./assets/img/blog/comment.svg" />
                                        </LikeButton>
                                    </span>{' '}
                                </span> */}
                                <span onClick={showModal} className="mr-10 cursor-pointes">
                                    {' '}
                                    <span>
                                        <LikeButton>
                                            <img src="/assets/img/blog/share.svg" />
                                        </LikeButton>
                                    </span>{' '}
                                </span>
                                {/* <span rel="noreferrer" className="mr-10">
                                    {' '}
                                    <span>
                                        <LikeButton>
                                            <img src="./assets/img/blog/bookmark.svg" />
                                        </LikeButton>
                                    </span>{' '}
                                </span> */}
                            </span>
                        </div>
                        <Share
                            visible={visible}
                            setVisible={setVisible}
                            shareURL={`http://huzz.africa/blog/details/${post?.id}`}
                        />
                    </>
                ))
            ) : (
                <div className="center">
                    <Space size="middle">
                        <Spin size="large" />
                    </Space>
                    {/* <Skeleton avatar paragraph={{ rows: 4 }} /> */}
                </div>
            )}

            {PostsData?.length <= 0 && <Skeleton avatar paragraph={{ rows: 10 }} />}
        </>
    );
}

export default HeroPost;
