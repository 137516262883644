import styled from 'styled-components';

const Container = styled.div`
    overflow: hidden;
`;

const Title = styled.h1`
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    width: max-content;
`;

const Content = styled.div`
    background-image: url(${(props) => props.url});
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    flex-shrink: 0;
`;

const ContentWrapper = styled.div`
    display: flex;
    overflow: auto;
    width: 100%;
    border: 5px solid #ffffff;
    border-radius: 10px;

    & > ${Content} {
        margin: 10px 10px;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
`;

const Button = styled.button`
    background: #ffffff;
    border: 0;
    color: #000000;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 12px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`;

export default {
    Container,
    Title,
    ContentWrapper,
    Content,
    ButtonWrapper,
    Button
};
