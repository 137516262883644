import React from 'react';
import { Link } from 'react-router-dom';
import { Space, Spin } from 'antd';
import Moment from 'moment';

function BasePosts({ PostsData }) {
    var last4 = []
    if(PostsData){

        last4 = PostsData.slice(-4).reverse();

    }
    return (
        <>
            {PostsData
                ? last4.map((post, index) => (
                      <div key={index} className="col-md-6  mt-2  p-10">
                          <div className="bg-white">
                              <div className="row">
                                  <div className="col-6 mt-2 ">
                                      <div className=" p-10 image-overflow max-h-140">
                                          <img
                                              src={`${
                                                  post.attributes.image_url
                                                      ? `https://cms.huzz.africa${post.attributes.image_url}`
                                                      : '/assets/img/blog/main-bg-sub-main.svg'
                                              }`}
                                              className="max-width-fill"
                                          />
                                      </div>
                                  </div>
                                  <div className="col-6   p-10">
                                      <div className="mr-10">
                                          <h4 className="weight-700 sm-elipsis-2">
                                              {post.attributes.title}
                                          </h4>
                                          {/* <p className="text-14 sm-elipsis-2 elipsis-3">
                                              {post.attributes.description}
                                          </p> */}
                                          <p
                                              className = 'text-14 sm-elipsis-2 elipsis-3'
                                              dangerouslySetInnerHTML={{
                                                  __html: post.attributes.description
                                              }}></p>
                                          
                                          <Link to={`/blog/details/${post.id}#`}>
                                              {' '}
                                              <p className="text-red underline text-14">
                                                  Read more
                                              </p>{' '}
                                          </Link>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  ))
                : ''}
        </>
    );
}

export default BasePosts;
