// import { useEffect, useState } from 'react';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import ScrollTop from '../../components/scrollTop';
import BlogNav from '../../components/Blog/BlogNav';
import BlogTopNav from '../../components/Blog/BlogTopNav';
import BlogShowcase from '../../components/Blog/BlogShowcase';
import '../../css/blog.css';
import { Link, useParams } from 'react-router-dom';
import '../../css/blogNavbar.css';
import { Pagination } from 'antd';
import { Tag, Divider } from 'antd';
import { Tags, BlogMenu, BlogCategories } from '../../dummyData';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetCategories, GetPosts, GetTags } from '../../redux/thunk/blog.thunks';
import HeroPost from '../../components/Blog/HeroPost';
import SearchBlog from '../../components/Blog/SearchBlog';
import RecentPosts from '../../components/Blog/RecentPosts';
import BasePosts from '../../components/Blog/BasePosts';
import CategoriesComponent from '../../components/Blog/Categories.Component';
import TagsComponent from '../../components/Blog/Tags.Component';

function Categories() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { isLoading, posts, tags, categories, errorMessage } = useSelector((state) => state.blog);
    useEffect(() => {
        dispatch(GetPosts());
        dispatch(GetTags());
        dispatch(GetCategories());
    }, []);

    let singleCategory = '';

    if (categories.data) {
        id = parseInt(id) - 1;
        singleCategory = categories.data[id];
        console.log(singleCategory.attributes.posts.data);
        console.log(id, typeof id);
    }

    // console.log(typeof singleCategory.attributes.posts.data);
    console.log(id, typeof id);

    return (
        <div className="blog">
            <BlogTopNav id="top" />
            <section className="blog-section section-one">
                <BlogShowcase />
            </section>
            <section className="main bg-teal">
                <section className="blog-section section-two">
                    <BlogNav categories={categories} />
                </section>
                <section className="container md-none">
                    <SearchBlog />
                    <div className="mb-20 bg-white p-10">
                        <span className="mr-10 tag-label pl-10">Categories</span>
                        <div className="row">
                            <CategoriesComponent categoriesData={categories.data} />
                        </div>
                    </div>
                </section>
                <section className="blog-showcase container">
                    <div className="row">
                        <div className="col-md-7 mt-2  p-10">
                            <div className="bg-white p-20">
                                {categories.data && (
                                    <HeroPost PostsData={singleCategory?.attributes?.posts?.data} />
                                )}
                            </div>
                            {/* {categories.data &&
                                singleCategory.attributes.posts.data
                                    .slice(0, 1)
                                    .map((post, index) => (
                                        <div className="bg-white p-20">
                                            <img
                                                src={`${
                                                    post.attributes.image_url
                                                        ? `https://cms.huzz.africa${post.attributes.image_url}`
                                                        : '/assets/gim / blog / main - bg.svg'
                                                }`}
                                                className="max-width-fill"
                                            />
                                            <div className="py-10">
                                                {' '}
                                                <span className="mr-10 tag-label">DEVELOPMENT</span>
                                                <span>October 13,2021</span>
                                            </div>
                                            <h1 className="tag-label text-green text-36">
                                                {post.attributes.title}
                                            </h1>
                                            <p className="sm-elipsis-6">
                                                {post.attributes.description}
                                            </p>
                                            <div className="py-10 flex justify-between">
                                                {' '}
                                                <span className="text-red underline">
                                                    {' '}
                                                    <Link
                                                        to={`/blog/details/${post.id}#`}
                                                        rel="noreferrer"
                                                        className="mr-10 text-red underline font-weight-500">
                                                        Read more
                                                    </Link>
                                                </span>
                                                <span className="flex">
                                                    <a
                                                        href="#"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="mr-10">
                                                        {' '}
                                                        <span>
                                                            <img src="./assets/img/blog/like.svg" />
                                                        </span>{' '}
                                                    </a>
                                                    <a
                                                        href="#"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="mr-10">
                                                        {' '}
                                                        <span>
                                                            <img src="./assets/img/blog/comment.svg" />
                                                        </span>{' '}
                                                    </a>
                                                    <a
                                                        href="#"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="mr-10">
                                                        {' '}
                                                        <span>
                                                            <img src="./assets/img/blog/share.svg" />
                                                        </span>{' '}
                                                    </a>
                                                    <a
                                                        href="#"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="mr-10">
                                                        {' '}
                                                        <span>
                                                            <img src="./assets/img/blog/bookmark.svg" />
                                                        </span>{' '}
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    ))} */}
                        </div>
                        <div className="col-md-5 mt-2  p-10 sm-none">
                            <div className=" ">
                                <SearchBlog />
                                <div className="mb-20 bg-white p-10 ">
                                    <span className="mr-10 tag-label pl-10">Categories</span>
                                    <div className="row">
                                        <CategoriesComponent categoriesData={categories.data} />
                                    </div>
                                </div>
                                <div className="mb-20 bg-white p-10">
                                    <span className="mr-10 tag-label pl-10">Recent Posts</span>

                                    <RecentPosts PostsData={posts.data} />
                                </div>
                                <div className="mb-20 bg-white p-10">
                                    <span className="mr-10 tag-label pl-10">Tags</span>
                                    <TagsComponent TagsData={tags.data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="showcase container ">
                    <div className="row">
                        {categories.data && (
                            <BasePosts PostsData={singleCategory?.attributes?.posts?.data} />
                        )}
                    </div>
                </section>
            </section>
            <section className=" bg-teal">
                <div className="showcase container m-auto flex justify-center p-10 ">
                    {/* <Pagination size="small" total={1} />{' '} */}
                </div>
                <br />
            </section>

            <ScrollTop />
            <Footer />
        </div>
    );
}
export default Categories;
