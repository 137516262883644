/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import ScrollTop from '../components/scrollTop';
import { Carousel, InvalidAvatar } from '../components/carousel';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useHistory, useLocation } from 'react-router-dom';
import { appLinks } from '../dummyData';
import Typewriter from 'typewriter-effect';
function Index() {
    useEffect(() => {
        AOS.init();
    });
    const history = useHistory();
    return (
        <div>
            <Navbar id="top" />
            <section className="showcase container hero">
                <div className="row">
                    <div className="col-md-6 mt-5 rmt-0 center-allign-text">
                        <h1 className="mt-5 " data-aos="fade-up">
                            Manage and grow <br /> your business{' '}
                        </h1>
                        <p data-aos="fade-right">
                            Get access to the essentials you need to run your business anywhere,
                            anytime - even offline! And guess what? It's absolutely free!
                        </p>
                        <h6 className="mt-3 mb-3 rd-none" data-aos="fade-left">
                            Entrepreneurs trust Huzz for{' '}
                            <span>
                                <a>
                                    <Typewriter
                                        class="typewrite"
                                        options={{
                                            strings: [
                                                'effective bookkeeping.',
                                                'inventory management.',
                                                'customer management.',
                                                'seamless payment solutions.',
                                                'actionable insights.',
                                                'faster debt recovery.'
                                            ],
                                            autoStart: true,
                                            loop: true
                                        }}
                                    />
                                    <span className="wrap"></span>
                                </a>
                            </span>
                        </h6>
                        <a href="#top">
                            {' '}
                            <button
                                className="btn mt-3 rd-none button"
                                data-aos="fade-up"
                                data-hover="Coming Soon"
                                data-active="I'M ACTIVE"
                            >
                                <span>Get Started</span>
                            </button>{' '}
                        </a>{' '}
                        <div className="mt-5 download-icons">
                            <div className="flex">
                                <div className="overlay-container">
                                    <div className="content">
                                        <a href={appLinks.ios} target="_blank" rel="noreferrer">
                                            <img
                                                className="content-image"
                                                src="./assets/img/icons/store-icon.svg"
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="overlay-container">
                                    <div className="content">
                                        <a href={appLinks.android} target="_blank" rel="noreferrer">
                                            <img
                                                className="content-image"
                                                src="./assets/img/google-play.svg"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="">
                            <img
                                className="phone-image-1 text animated bounce"
                                src="./assets/img/Group34041.svg"
                                data-aos="fade-up"
                            />
                            <img
                                className="phone-image-2 text animated pulse"
                                alt=""
                                src="./assets/img/Rectangle.png"
                                data-aos="fade-left"
                            />
                        </div>
                        <div className="wrapper">
                            <div className="name_container">
                                <div className="name"></div>
                                <div className="designation"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className="col-sm-12 col-md-8 m-auto social-proof ">
                    <div className="col-md-8 col-sm-12 m-auto ">
                        <div className="coloured ">
                            <h2>Trusted by 200+ businesses</h2>
                            <img
                                className="why-huzz-icons icon-1"
                                alt=""
                                src="./assets/img/customer-icons/customer-1.jpg"
                                data-aos="fade-right"
                            />
                            <img
                                className="why-huzz-icons icon-2"
                                alt=""
                                src="./assets/img/customer-icons/customer-2.jpg"
                                data-aos="fade-right"
                            />
                            <InvalidAvatar
                                placeholder={'S'}
                                customClass={'icon-3 testimonial-avatar'}
                                dataAos={'fade-up'}
                            />
                            <InvalidAvatar
                                placeholder={'J'}
                                customClass={'icon-4 testimonial-avatar'}
                                dataAos={'fade-left'}
                            />
                            <InvalidAvatar
                                placeholder={'O'}
                                customClass={'icon-5 testimonial-avatar'}
                                dataAos={'fade-left'}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="why-huzz mt-5 rmt-0">
                <br />
                <br />
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mt-5">
                            <div className="container">
                                <h1
                                    className="mt-5 ml-5 mb-5 r-center rmt-0 rml-0"
                                    data-aos="fade-up"
                                >
                                    WHY HUZZ?
                                </h1>
                                <div>
                                    <div className="coloured mt-5 animated bounce">
                                        <p data-aos="fade-up">
                                            Log, manage and keep track of your business or personal
                                            transactions
                                        </p>
                                        <img
                                            className="why-huzz-icons"
                                            alt=""
                                            src="./assets/img/icons/Group3453.svg"
                                            data-aos="fade-left"
                                        />
                                    </div>
                                </div>
                                <div className="monochrome coloured mt-5 animated pulse">
                                    <p data-aos="fade-up">
                                        Intelligent receipt and invoice generation tools for your
                                        business.
                                    </p>
                                    <img
                                        className="why-huzz-icons"
                                        alt=""
                                        src="./assets/img/icons/Group3456.svg"
                                        data-aos="fade-left"
                                    />
                                </div>
                                <div className="coloured mt-5 animated bounce">
                                    <p data-aos="fade-up">
                                        Set up one-time or repeated reminders to customers or
                                        yourself.
                                    </p>
                                    <img
                                        className="why-huzz-icons"
                                        alt=""
                                        src="./assets/img/icons/Group3457.svg"
                                        data-aos="fade-left"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="container">
                                <div className=" monochrome mt-5 animated pulse">
                                    <p data-aos="fade-up">
                                        Manage multiple businesses and invite your partners.
                                    </p>
                                    <img
                                        className="why-huzz-icons"
                                        alt=""
                                        src="./assets/img/icons/Group3452.svg"
                                        data-aos="fade-left"
                                    />
                                </div>
                                <div className="coloured mt-5 animated bounce">
                                    <p data-aos="fade-up">
                                        Generate business or customer insights and make smarter
                                        decisions.
                                    </p>
                                    <img
                                        className="why-huzz-icons"
                                        alt=""
                                        src="./assets/img/icons/Group3454.svg"
                                        data-aos="fade-left"
                                    />
                                </div>
                                <div className="monochrome mt-5 animated pulse">
                                    <p data-aos="fade-up">
                                        Manage your customers and products with absolute ease
                                    </p>
                                    <img
                                        className="why-huzz-icons"
                                        alt=""
                                        src="./assets/img/icons/Group3455.svg"
                                        data-aos="fade-left"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="showcase container ">
                <br />
                <br />
                <br />
                <div className="row">
                    <div className="col-md-6 mt-20 center-allign-text">
                        <h1 data-aos="fade-up">You can literally count on Huzz</h1>
                        <div className="mt-5 d-none">
                            <center>
                                <img
                                    className="animated bounce mt-5"
                                    width="80%"
                                    src="./assets/img/blue2.svg"
                                />
                            </center>{' '}
                            <br />
                        </div>
                        <p data-aos="fade-up">
                            Log your daily income and expenditure. See how your business is really
                            doing and track your money. We meant every word when we said you can
                            count on Huzz.
                        </p>
                        <a href="#top">
                            {' '}
                            <button
                                className="btn mt-5 r-center button"
                                data-aos="fade-right"
                                data-hover="Coming Soon"
                                data-active="I'M ACTIVE"
                            >
                                <span>Open Account</span>
                            </button>{' '}
                        </a>
                    </div>
                    <div className="col-md-6 rd-none">
                        <div className="mt-5">
                            <center>
                                <img
                                    className="animated bounce mt-5"
                                    width="80%"
                                    src="./assets/img/blue2.svg"
                                />
                            </center>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
            </section>
            <section className="why-huzz mt-5 ">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-6 mt-5 mb-3 rd-none">
                            <center>
                                <img
                                    width="60%"
                                    className="animated bounce"
                                    src="./assets/img/Group34071.svg"
                                />
                            </center>
                        </div>
                        <div className="col-md-6 mt-20">
                            <h1 data-aos="fade-up">It works offline</h1>
                            <div className="col-md-6 mt-5 mb-3 d-none">
                                <center>
                                    <img
                                        width="60%"
                                        className="animated bounce"
                                        src="./assets/img/Group34071.svg"
                                    />
                                </center>
                            </div>
                            <p className="mt-5" data-aos="fade-up">
                                We know internet issues can be a pain in the ass sometimes. So we've
                                put in extra work to make sure Huzz works optimally even when you're
                                offline.
                            </p>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </section>
            <section className="showcase container">
                <br />
                <br />
                <br />
                <div className="row">
                    <div className="col-md-6 mt-2 center-allign-text">
                        <h1 data-aos="fade-up">Insights for better business decisions</h1>
                        <div className="d-none">
                            <br />
                            <center>
                                {' '}
                                <img
                                    className="animated bounce"
                                    width="70%"
                                    src="./assets/img/Untitled-51.svg"
                                />
                            </center>
                        </div>
                        <br />
                        <br />
                        <p data-aos="fade-up">
                            Maximize our easy to comprehend analytics and visualization tools.
                            Generate and export useful reports and insights about your business and
                            make better decisions.
                        </p>
                        <a href="#top">
                            {' '}
                            <button
                                className="btn mt-5 r-center button"
                                data-aos="fade-up"
                                data-hover="Coming Soon"
                                data-active="I'M ACTIVE"
                            >
                                <span>View Insight</span>
                            </button>
                        </a>
                    </div>
                    <div className="col-md-6 rd-none">
                        <div className="">
                            <br />
                            <center>
                                {' '}
                                <img
                                    className="animated bounce"
                                    width="70%"
                                    src="./assets/img/Untitled-51.svg"
                                />
                            </center>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
            </section>
            <section className="why-huzz">
                <br />
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-md-6 mt-5">
                            <center>
                                {' '}
                                <img
                                    className="animated bounce rd-none mt-5 mb-5"
                                    width="60%"
                                    src="./assets/img/invoive1.svg"
                                />
                            </center>
                        </div>
                        <div className="col-md-6 mt-20">
                            <h1 data-aos="fade-up">Issue invoices or receipts like a pro</h1>
                            <br />
                            <br />
                            <center>
                                {' '}
                                <img
                                    className="animated bounce d-none"
                                    width="60%"
                                    src="./assets/img/invoive1.svg"
                                />
                            </center>
                            <br />
                            <p data-aos="fade-up">
                                Take advantage of our intelligent and intuitive invoice and receipt
                                generating tools and issue invoices and receipts in less than 1
                                minute.
                            </p>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </section>
            <section className="showcase container">
                <br />
                <br />
                <br />
                <div className="row">
                    <div className="col-md-6 mt-4 center-allign-text">
                        <h1 data-aos="fade-up">Mini CRM in your pocket. Believe it!</h1>
                        <br />
                        <div className="">
                            <center>
                                <img
                                    className="animated bounce d-none"
                                    width="70%"
                                    src="./assets/img/illust2.svg"
                                />
                            </center>
                        </div>
                        <br />
                        <p data-aos="fade-up">
                            Customer management has never been easier with Huzz. Manually add new
                            customer information or quickly import customers already in your contact
                            list.
                        </p>
                        <a href="#top">
                            {' '}
                            <button
                                className="btn mt-5 button"
                                data-aos="fade-up"
                                data-hover="Coming Soon"
                                data-active="I'M ACTIVE"
                            >
                                <span>Open Account</span>
                            </button>
                        </a>
                    </div>
                    <div className="col-md-6">
                        <div className="">
                            <center>
                                <img
                                    className="animated bounce rd-none"
                                    width="70%"
                                    src="./assets/img/illust2.svg"
                                />
                            </center>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
            </section>
            <Carousel />
            <section className="why-huzz ">
                <br />
                <br />
                <div className="container">
                    <div className="row mt-5 mb-5 m-2 center-allign-text">
                        <h1 data-aos="fade-up">Do more with Huzz</h1>
                        <br />
                        <br />
                        <div className="col-md-3 mt-5">
                            <img
                                className=""
                                alt=""
                                src="./assets/img/icons/Group34641.svg"
                                data-aos="fade-right"
                            />
                            <br />
                            <br />
                            <h5 data-aos="fade-up">
                                <b>Easy and effective collaboration </b>
                            </h5>
                            <p data-aos="fade-up">
                                Invite your business partners or employees to collaboratively manage
                                your business. Easily set privileges to ensure your data is
                                protected.
                            </p>
                            <br />
                        </div>
                        <div className="col-md-3 mt-5">
                            <img
                                className=""
                                alt=""
                                src="./assets/img/icons/Group34642.svg"
                                data-aos="fade-up"
                            />
                            <br />
                            <br />
                            <h5 data-aos="fade-up">
                                <b>Your products everywhere you go </b>
                            </h5>
                            <p data-aos="fade-up">
                                Organize your products and services on your Huzz app. Instantly
                                access and share them with existing or potential customers.
                            </p>
                            <br />
                        </div>
                        <div className="col-md-3 mt-5">
                            <img
                                className=""
                                alt=""
                                src="./assets/img/icons/Group34643.svg"
                                data-aos="fade-left"
                            />
                            <br />
                            <br />
                            <h5 data-aos="fade-up">
                                <b>Receive payments in a breeze (coming soon)</b>
                            </h5>
                            <p data-aos="fade-up">
                                Create a dedicated bank account for your business and link it to
                                your Huzz wallet. Receive payments via bank transfer or customized
                                links.
                            </p>
                            <br />
                        </div>
                    </div>
                    <div className="col-md-6 center-allign-text m-auto">
                        <center>
                            {' '}
                            <a href="#top">
                                {' '}
                                <button
                                    className="btn mt-3 button"
                                    data-aos="fade-up"
                                    data-hover="Coming Soon"
                                    data-active="I'M ACTIVE"
                                >
                                    <span>Get Started</span>
                                </button>
                            </a>
                        </center>
                        <div className="flex mt-5 download-icons flex-why-huzz">
                            <div className="overlay-container">
                                <div className="content">
                                    <a href={appLinks.ios} target="_blank" rel="noreferrer">
                                        <img
                                            className="content-image"
                                            src="./assets/img/icons/store-icon.svg"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="overlay-container">
                                <div className="content">
                                    <a href={appLinks.android} target="_blank" rel="noreferrer">
                                        <img
                                            className="content-image"
                                            src="./assets/img/google-play.svg"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </section>
            <ScrollTop />
            <Footer />
        </div>
    );
}
export default Index;
