import { useEffect } from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import Accordion from '../components/accordion';
import ScrollTop from '../components/scrollTop';
import { dummyAccordionData, sampleTableData } from '../dummyData';
import AOS from 'aos';
import 'aos/dist/aos.css';
function PrivacyPolicy() {
    useEffect(() => {
        AOS.init();
    });
    console.log(dummyAccordionData);
    return (
        <div>
            <Navbar id="top" />
            <section className="showcase container faq-hero privacy-policy">
                <div className="row">
                    <section className=" container">
                        <div className="row mb-5">
                            <div className="col-md-3 mt-20 left rd-none">
                                <p>
                                    <b>CONTENTS</b>
                                </p>
                                <a href="#1">
                                    {' '}
                                    <p> Policy</p>{' '}
                                </a>
                                <a href="#2">
                                    {' '}
                                    <p> Information We Collect</p>
                                </a>
                                <a href="#3">
                                    {' '}
                                    <p> Usin Personally Identifiable Informationg Your</p>{' '}
                                </a>
                                <a href="#4">
                                    {' '}
                                    <p> Non-personal identification information</p>{' '}
                                </a>
                                <a href="#5">
                                    {' '}
                                    <p> Web Browser Cookies</p>{' '}
                                </a>
                                <a href="#6">
                                    {' '}
                                    <p> Age Restriction & Legal Authority</p>{' '}
                                </a>
                                <a href="#7">
                                    {' '}
                                    <p> Disclosures</p>{' '}
                                </a>
                                <a href="#8">
                                    {' '}
                                    <p> Access or Change to Personal Data</p>{' '}
                                </a>
                                <a href="#9">
                                    {' '}
                                    <p> Website Security</p>{' '}
                                </a>
                                <a href="#10">
                                    {' '}
                                    <p> The Data We Retain</p>{' '}
                                </a>
                                <a href="#11">
                                    {' '}
                                    <p> Links to Third Party Websites</p>{' '}
                                </a>
                                <a href="#12">
                                    {' '}
                                    <p> Limitation to Liability</p>{' '}
                                </a>
                                <a href="#13">
                                    {' '}
                                    <p> Problem Signing In</p>{' '}
                                </a>
                                <a href="#14">
                                    {' '}
                                    <p> Changes to This Privacy Policy</p>{' '}
                                </a>
                                <a href="#15">
                                    {' '}
                                    <p> Your Acceptance of these Terms</p>{' '}
                                </a>
                                <a href="#16">
                                    {' '}
                                    <p> Contacting Us</p>{' '}
                                </a>
                            </div>
                            <div className="col-md-9 mt-5 right">
                                <h1 id="1">Privacy policy</h1> <br />
                                <p>
                                    Huzz values your privacy and appreciates your trust in us. This
                                    Privacy Policy explains how we use, share and protect the
                                    personally identifiable information (PII) that we collect from
                                    you and the choices you have.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    Throughout the website, the terms &ldquo;we&rdquo;,
                                    &ldquo;us&rdquo; and &ldquo;our&rdquo; refer to Huzz. And the
                                    term &ldquo;app&rdquo; refers to our Android or iOS app.
                                </p>
                                <p>
                                    If you see an undefined term in this Privacy Policy, it has the
                                    same definition as outlined in our Terms and Conditions.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    This Privacy Policy governs the manner in which we collect, use,
                                    maintain and disclose information collected from users (each, a
                                    &ldquo;User&rdquo;) on our website or app. This Privacy Policy
                                    does not relate to other websites to which we link.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    By visiting our website or using our app, you agree to this
                                    Privacy Policy.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    Our website or app is not directed at you if we are prohibited
                                    by any law of any jurisdiction from making the information on
                                    our website or app available to you and is not intended for any
                                    use that would be contrary to local law or regulation.
                                </p>
                                <p id="2">
                                    <br />
                                </p>
                                <h5>Information We Collect</h5>
                                <p>
                                    There are two categories of information we collect and the
                                    extent of the information we collect may differ depending on
                                    whether you use our website or app. We collect a variety of
                                    information from our Users and visitors to our website or app.
                                    As described below, some information is automatically collected
                                    when you visit our website or app, and some you provide to us
                                    when registering an account or filling out a form, or
                                    communicating with us.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    Information Collected Automatically: Whenever you visit our
                                    website, our web servers automatically collect non-personal
                                    information such as the domain name of the internet access
                                    provider, the internet protocol address used to connect the
                                    computer to the internet, the average time spent on our website,
                                    pages viewed, information searched for, access times, and other
                                    relevant statistics. We do not collect these data when you use
                                    our app
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    Information You Provide Us: If you provide us with personal
                                    information by registering for an account or contacting us, we
                                    collect that personal information. For example, if you register
                                    on our website or app, we collect your name, e-mail address and
                                    password. If you contact us, you may also provide us with
                                    personal information that we collect.
                                </p>
                                <br />
                                <p>
                                    Further, upon downloading the app, You are requested to enable
                                    the app to access your contact list (as stored on your mobile
                                    device on which the app is downloaded), for the purpose of you
                                    adding one or more of the contacts as your customers, so as to
                                    allow you to link transactions, debts, and invoices generated on
                                    the app to those customers (who might be or might not be
                                    registered on the app). The said contact information you provide
                                    is stored both offline and in the cloud until you delete them.
                                </p>
                                <p id="3">
                                    <br />
                                </p>
                                <h5>Using Your Personally Identifiable Information</h5>
                                <p>
                                    &ldquo;Personally Identifiable Information&rdquo; means any
                                    information (a) that identifies or can be used to identify,
                                    contact, or locate the person to whom such information pertains,
                                    or (b) from which identification or contact information of an
                                    individual person can be derived.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    Personal Data about our Users is an important part of our
                                    business and we shall only use your personal data for the
                                    following purposes and shall not keep such personal data longer
                                    than it is necessary to fulfil these purposes:
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>a. To help us verify your identity;</p>
                                <p>
                                    b. To help us identify and manage accounts you could have with
                                    us from time to time;
                                </p>
                                <p>c. To respond to requests that you make of us;</p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    Enable us operate, protect, improve, and optimize our website by
                                    carrying out marketing analysis and user profiling (including
                                    transactional information), conduct research, including creating
                                    statistical and testing information;
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>Provide customer service;</p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    Send you service or support messages, such as updates, security
                                    alerts, and account notifications;
                                </p>
                                <p>
                                    Verify or authenticate information or identifications provided
                                    by you (such as to verify your address or compare your
                                    identification photo to another photo you provide);
                                </p>
                                <p>
                                    To help us to administer and to contact you about improved
                                    administration of any accounts and services we have provided in
                                    the past;
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>To help prevent and detect fraud or loss;</p>
                                <p>Conduct investigations and risk assessments;</p>
                                <p>
                                    To allow us contact you in any way (including e-mail, telephone,
                                    and text or multimedia message) about services offered by us
                                    unless you have previously asked us not to do so.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    We may monitor and record communications with you (including
                                    phone conversations and emails) for quality assurance and
                                    compliance. We shall not disclose your personal data to any
                                    third party except in accordance with this Privacy Policy.
                                </p>
                                <p id="4">
                                    <br />
                                </p>
                                <h5>Non-personal identification information</h5>
                                <p>
                                    We may collect non-personal identification information about
                                    Users whenever they interact with our website. Non-personal
                                    identification information may include the browser name, the
                                    type of computer and technical information about the
                                    Users&rsquo; means of connection to our website, such as the
                                    operating system and other similar information.
                                </p>
                                <p id="5">
                                    <br />
                                </p>
                                <h5>Web Browser Cookies</h5>
                                <p>
                                    Our website may use &ldquo;cookies&rdquo; to enhance User
                                    experience. User&rsquo;s web browser places cookies on their
                                    hard drive for record-keeping purposes and sometimes to track
                                    information about them. You may choose to set your web browser
                                    to refuse cookies, or to alert you when cookies are being sent.
                                    If they do so, note that some parts of the Site may not function
                                    properly.
                                </p>
                                <p id="6">
                                    <br />
                                </p>
                                <h5>Age Restriction &amp; Legal Authority</h5>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    You affirm that you are registering an account for a company or
                                    other legal entity and you represent and warrant the following:
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>That you are over the age of majority;</p>
                                <p>The company or legal entity is duly registered; and</p>
                                <p>
                                    You have the authority to legally bind that entity in a legal
                                    contract.
                                </p>
                                <p id="7">
                                    <br />
                                </p>
                                <h5>Disclosures</h5>
                                <p>
                                    We will not sell, publish, or disclose to third parties your
                                    personally identifiable information collected on our website,
                                    through our servers or otherwise obtained by us, other than to
                                    provide our services and as set forth in this privacy policy. We
                                    may share generic aggregated demographic information not linked
                                    to any personally identifiable information regarding visitors
                                    and Users with our trusted affiliates for the purposes outlined
                                    above. We may share your information with these third parties
                                    for those limited purposes provided that you have given us your
                                    permission. Albeit, the aforesaid information will not be
                                    collected or shared when you use our app.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    We may request information about you from third parties to
                                    provide our services.
                                </p>
                                <p>
                                    We will notify you as soon as we become aware of a harmful data
                                    breach which may result in a risk of your rights and freedom.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    You have the right to request an erasure of your data at any
                                    time.
                                </p>
                                <p>We will notify you if we are transferring your data.</p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    You may request at any time that we halt further dissemination
                                    of your data or cease to use your data.
                                </p>
                                <p>
                                    If you submit content in a public forum or a social media post,
                                    or use a similar feature on our website, that content is
                                    publicly visible.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    We may disclose Personally Identifiable Information if required
                                    to do so by law or in the good faith belief that such action is
                                    necessary to (a) conform with the requirements of the law or
                                    comply with legal process served on us, or (b) act in urgent
                                    circumstances to protect the personal safety of users of our
                                    service or members of the public.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    We may disclose Personally Identifiable Information if required
                                    to do so by law or in the good faith belief that such action is
                                    necessary to (a) conform with the requirements of the law or
                                    comply with legal process served on us, or (b) act in urgent
                                    circumstances to protect the personal safety of users of our
                                    service or members of the public.
                                </p>
                                <p id="8">
                                    <br />
                                </p>
                                <h5>Disclosures</h5>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    Pursuant to you registering on our website, you will receive
                                    emails that confirm specific actions you requested. You will
                                    receive notifications confirming your registration, the
                                    successful verification of email addresses and bank accounts, if
                                    required, and confirmation of successful applications. You will
                                    also receive progress updates on the status of your application.
                                    These are notifications that you cannot opt out of receiving, as
                                    they are in place to protect the security of your account and
                                    your personal information. We may also send you responses to
                                    emails you send us, if appropriate or applicable. From time to
                                    time, we will also send User surveys, request for User feedback
                                    regarding User experience and our website operations or
                                    marketing offers from us or from us, on behalf of our marketing
                                    partners. The completion of these surveys or requests for
                                    feedback or acceptance of any offer is strictly voluntary. If
                                    you do not wish to receive these offers, surveys or User
                                    feedback emails, please opt out in any offer email you receive
                                    from us.
                                </p>
                                <p id="9">
                                    <br />
                                </p>
                                <h5>Access or Change to Personal Data</h5>
                                <p>
                                    Upon request, we will provide you with information about any of
                                    your personal information in our possession. If your personal
                                    data changes, you may correct, update or amend, by making the
                                    change on your personalized account or by sending an email to
                                    info@huzz.africa. If you wish to delete or deactivate your
                                    account or choose to no longer receive emails from us, please
                                    email us at info@huzz.africa.
                                </p>
                                <p id="10">
                                    <br />
                                </p>
                                <h5>Website Security</h5>
                                <p>
                                    We are committed to ensuring that your information is secure. In
                                    order to prevent unauthorised access or disclosure, we have put
                                    in place suitable physical, electronic and managerial procedures
                                    such as secure sockets layer (SSL) to safeguard and secure the
                                    information we collect online. We use encryption tools when
                                    accepting and transmitting delicate visitor information through
                                    our website.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    In addition to our substantial efforts, you can take several
                                    precautions to protect the security of your computer and
                                    personal information. For instance, when you are creating an
                                    account, endeavor to use a well-chosen password. You should
                                    avoid using an information that others can easily learn about
                                    you, such as a family member&rsquo;s name or birthday and you
                                    can also use special characters in place of letters. You may
                                    change the password as often as you wish by going to
                                    &ldquo;Settings&rdquo; on your dashboard and selecting
                                    &ldquo;Change Password&rdquo;. We also recommend that you change
                                    your password frequently. You can also install and regularly
                                    update antivirus and firewall software to protect your computer
                                    from external attacks by malicious users. When you are finished
                                    with a session on our website, be sure that you log out and
                                    close the browser window. We only authorize those persons who
                                    need to know your personal information to administer your
                                    account, to provide or inform you about products and services or
                                    to maintain improve or administer our website to access your
                                    personal information.
                                </p>
                                <p id="11">
                                    <br />
                                </p>
                                <h5>The Data We Retain</h5>
                                <p>
                                    We will retain your information for as long as your account is
                                    active or as needed to provide you with our services, comply
                                    with our legal and statutory obligations or verify your
                                    information with a financial institution. We are statutory
                                    obligated to retain the data you provide us with in order to
                                    process transactions, ensure settlements, make refunds, identify
                                    fraud and in compliance with laws and regulatory guidelines
                                    applicable to us, our banking providers and credit card
                                    processors. Therefore, even after closing your account, we will
                                    retain certain data to comply with these obligations.
                                </p>
                                <p id="12">
                                    <br />
                                </p>
                                <h5>Links to Third Party Websites</h5>
                                <p>
                                    Our website may contain links to third-party websites or
                                    services that are not owned or controlled by us.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    have no control over, and assumes no responsibility for, the
                                    content, privacy policies, or practices of any third-party
                                    websites or services. You further acknowledge and agree that we
                                    shall not be responsible or liable, directly or indirectly, for
                                    any damage or loss caused or alleged to be caused by or in
                                    connection with use of or reliance on any such content, goods or
                                    services available on or through any such web sites or services.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    We strongly advise you to read the terms and conditions and
                                    privacy policies of any third- party web sites or services that
                                    you visit.
                                </p>
                                <p id="13">
                                    <br />
                                </p>
                                <h5>Limitation to Liability</h5>
                                <p>
                                    We exercise reasonable efforts to safeguard the security and
                                    confidentiality of your personal data; however, we will not be
                                    liable for unauthorized disclosure of personal data that occurs
                                    through no fault of ours including, but not limited to, errors
                                    in transmission access to your account by anyone who uses your
                                    user ID and password, and your failure to comply with your
                                    security obligations.
                                </p>
                                <p id="14">
                                    <br />
                                </p>
                                <h5>Problem Signing In</h5>
                                <p>
                                    Our sign-in process is designed to help protect your privacy. If
                                    you have trouble signing in to our website, please ensure that
                                    you are using your registered e-mail address and/or correct
                                    password. If you are using the registered e-mail address and
                                    correct password, and you continue to have trouble signing in to
                                    our website, please send an e-mail to info@huzz.africa
                                </p>
                                <p id="15">
                                    <br />
                                </p>
                                <h5>Changes to This Privacy Policy</h5>
                                <p>
                                    We have the discretion to update our privacy policy at any time.
                                    When we do, we will post a notification on the main page of our
                                    website. We encourage Users to frequently check this page for
                                    any changes to stay informed about how we are helping to protect
                                    the personal information we collect. You acknowledge and agree
                                    that it is your responsibility to review this privacy policy
                                    periodically and become aware of modifications.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <h5>Your Acceptance of these Terms</h5>
                                <p>
                                    By using our website, you signify your acceptance of this
                                    policy. If you do not agree to this policy, please do not use
                                    our website. Your continued use of our website following the
                                    positing of changes to this policy will be deemed your
                                    acceptance of those changes.
                                </p>
                                <p id="16">
                                    <br />
                                </p>
                                <h5>Contacting Us</h5>
                                <p>
                                    If you have any questions about this privacy policy, the
                                    practices of our website, or your dealings with our website,
                                    please contact us at info@huzz.africa.
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    <br />
                                </p>
                                <p>
                                    <br />
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <ScrollTop />
            <Footer />
        </div>
    );
}
export default PrivacyPolicy;
