export const PostsActions = {
	POSTS_LOAD_START: "POSTS_LOAD_START",
	POSTS_LOAD_SUCCESS: "POSTS_LOAD_SUCCESS",
	POSTS_LOAD_ERROR: "POSTS_LOAD_ERROR",
};
export const TagsActions = {
	TAGS_LOAD_START: "TAGS_LOAD_START",
	TAGS_LOAD_SUCCESS: "TAGS_LOAD_SUCCESS",
	TAGS_LOAD_ERROR: "TAGS_LOAD_ERROR",
};
export const CategoriesActions = {
	CATEGORIES_LOAD_START: "CATEGORIES_LOAD_START",
	CATEGORIES_LOAD_SUCCESS: "CATEGORIES_LOAD_SUCCESS",
	CATEGORIES_LOAD_ERROR: "CATEGORIES_LOAD_ERROR",
};
export const SinglePostActions = {
	SINGLE_POST_LOAD_START: "SINGLE_POST_LOAD_START",
	SINGLE_POST_LOAD_SUCCESS: "SINGLE_POST_LOAD_SUCCESS",
	SINGLE_POST_LOAD_ERROR: "SINGLE_POST_LOAD_ERROR",
};
