import React, { Fragment } from 'react';
import './css/App.css';
import Index from './screens/Index';
import Blog from './screens/blog/Index';
import Detail from './screens/blog/Detail';
import Categories from './screens/blog/Categories';
import Tags from './screens/blog/Tags';
import FAQ from './screens/FAQ';
import PrivacyPolicy from './screens/PrivacyPolicy';
import TermsOfUse from './screens/TermsOfUse';
import CommingSoon from './screens/CommingSoon';
import Test from './Test';
import MobileFAQ from './screens/mobile/FAQ';
import MobilePrivacyPolicy from './screens/mobile/PrivacyPolicy';
import MobileTermsOfUse from './screens/mobile/TermsOfUse';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from './utility/ScrollToTop';
import CustomeSwitch from './utility/CustomeSwitch';

function App() {
    return (
        <Router>
            <CustomeSwitch>
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/" component={Index} />
                        <Route path="/faq" component={FAQ} />
                        <Route path="/privacy-policy" component={PrivacyPolicy} />
                        <Route path="/terms-of-use" component={TermsOfUse} />
                        <Route path="/comming-soon" component={CommingSoon} />
                        <Route path="/mobile/terms-of-use" component={MobileTermsOfUse} />
                        <Route path="/mobile/privacy-policy" component={MobilePrivacyPolicy} />
                        <Route path="/mobile/faq" component={MobileFAQ} />
                        <Route path="/test" component={Test} />
                        <Route exact path="/blog/details/:id" component={Detail} />;
                        <Route exact path="/blog/categories/:id" component={Categories} />;
                        <Route exact path="/blog/tags/:id" component={Tags} />;
                        <Route path="/blog" component={Blog} />
                    </Switch>
                </ScrollToTop>
            </CustomeSwitch>
        </Router>
    );
}
export default App;
